@font-face {
  font-family: 'NotoSansMono';
  src: url('../src/assets/fonts/NotoSansMono-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins-Bold';
  src: url('../src/assets/fonts/Poppins-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins-Regular';
  src: url('../src/assets/fonts/Poppins-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins-Medium';
  src: url('../src/assets/fonts/Poppins-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'RobotoMono';
  src: url('../src/assets/fonts/RobotoMono-Regular-9.ttf') format('truetype');
}
/* 滚动条的滑块 */
::-webkit-scrollbar-thumb {
  background-color: #242424;
  border-radius: 3px;
}
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

html,
body,
div,
p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
dl,
dt,
dd,
table,
tr,
td,
th,
tbody,
thead,
span,
i,
a,
input,
button,
textarea {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box !important;
  font-family: 'RobotoMono' !important;
}

body {
  background: #000;
  overflow: auto !important;
  width: 100% !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  font-size: inherit;
}

ul {
  list-style: none;
}

input,
button,
textarea {
  outline: none;
}

button {
  border: none;
  cursor: pointer;
}

a {
  text-decoration: none;
}

.h5-box {
  display: none;
}

.pc-box {
  display: block;
}

@media screen and (max-width: 750px) {
  .pc-box {
    display: none;
  }

  .h5-box {
    display: block;
  }
}
