.home_container {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  position: relative;
  .center {
    width: 1160px;
    margin: auto;
  }
  .part_title {
    font-size: 40px;
    color: #fff;
    line-height: 42px;
    text-align: center;
    padding-top: 160px;
  }
  .h5_btn{
    display: none;
  }
  .icon_menu{
    display: none;
  }
  .socical{
    display: flex;
    width: 48%;
    justify-content: space-between;
    .item{
      display: flex;
      flex-direction: column;
      .tit{
        color: #FFFFFF;
        font-size: 14px;
      }
      .link-item{
        display: flex;
        margin-top: 20px;
        &:hover{
          a{
            color: #fff;
          }
          .default{
            display: none;
          }
          .hover{
            display: block;
          }
        }
        a{
          color: rgba(#fff,0.7);
          font-size: 14px;
        }
        img{
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }
        .hover{
          display: none;
        }
        .blog{
          color: #FFFFFF;
          font-size: 14px;
        }
      }
      .blog-item{
        margin-top: 0;
      }
    }
  }
}

@media screen and (min-width: 1920px) {
  .home_container {
    .center {
      width: 1400px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .home_container {
    .center {
      width: 100%;
      padding: 0 20px;
    }
    .part_title{
      padding-top: 120px;
    }
  }
}

@media screen and (max-width: 960px) {
  .home_container{
    .part_title{
      font-size: 32px;
    }
  }
}
@media screen and (max-width: 750px) {
  .home_container {
    width: 100%;
    .center {
      width: 100%;
      padding: 0;
    }
    .part_title{
      font-size: 24px;
      line-height: 48px;
      text-align: center !important;
      padding-top: 60px;
    }
    .pc_btn{
      display: none;
    }
    .pc_logo{
      width: 145px !important;
      height: 28px !important;
    }
  }
}

@primary-color: #1DA57A;