.footer_block{
  width: 100%;
  background: #131313;
  padding-top: 60px;
  .center{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .pc_logo {
    width: 220px;
    height: 42px;
  }
  .top{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 48px;
  }
  .community{
    display: flex;
    align-items: center;
    
    .community-item{
      height: 24px;
      a{
        width: 24px;
        height: 24px;
        display: inline-block;
        img{
          width: 100%;
          height: 100%;
        }
      }
      margin-left: 30px;
      &:hover{
        .default{
          display: none;
        }
        .hover{
          display: block;
        }
      }
      .hover{
        display: none;
      }
    }
    .community-item:first-child {
      margin-left: 0;
    }
  }
  .socical{
    width: 100%;
    margin-bottom: 60px;
    .item{
      display: flex;
      flex-direction: column;
      .tit{
        color: #FFFFFF;
        font-size: 14px;
      }
      .link-item{
        margin-top: 28px;
        position: relative;
        &:hover{
          .contact{
            display: flex;
          }
        }
        .contact{
          position: absolute;
          bottom: 20px;
          right:0%;
          background: #ffffff0d;
          width: 100%;
          width: 150px;
          text-align: center;
          height: 28px;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          display: none;
          p{
            font-size: 12px;
            color: #fff;
          }
          img{
            width: 16px;
            height: 16px;
            margin-right: 0;
            margin-left: 4px;
          }
          .down_img{
            margin-left: 0;
            margin-right: 4px;
          }
        }
      }
    }
  }
  .copyright {
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding-top: 20px;
    font-size: 12px;
    color: #ffffff;
    opacity: 0.5;
    line-height: 12px;
    text-align: center;
    font-weight: 400;
    padding-bottom: 32px;
  }
}

@media screen and (max-width: 750px) {
  .footer_block {
    .copyright {
      margin-top: 12px;
    }
    margin-top: -100px;
    .center{
      align-items: unset;
      .top{
        margin-bottom: 40px;
        padding-left: 16px;
        // justify-content: center;
      }
      .community {
        justify-content: flex-end;
        padding-right: 16px;
      }
      .socical{
        padding: 0 16px;
        flex-wrap: wrap;
        margin-bottom: 0;
        .item{
          width: 50%;
          margin-bottom: 60px;
          .link-item{
            margin-top: 20px;
            .contact{
              right: unset;
              left: 0;
            }
          }
        }
      }
    }
    .copyright {
      text-align: left;
      padding-left: 16px;
    }
  }
}

@primary-color: #1DA57A;