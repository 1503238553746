.tucana_build{
  position: relative;
  margin-bottom: 20px;
  overflow: hidden;
  .center{
    .part_title{
      text-align: left;
    }
    .desc{
      margin-top: 28px;
      font-size: 18px;
      color: rgba(#fff,0.7);
      font-weight: 400;
      line-height: 20px;
      text-shadow: 3px 2px 17px rgba(0,0,0,0.5);
      font-style: normal;
    }
    .btn{
      color: #fff;
      margin-top: 30px;
      span::before {
        content: "Get Started";
      }
      span{
        margin-left: -4px;
      }
    }
    button:hover {
      span::before {
        content: "Coming Soon";
      }
    }
    .list_animation{
      display: flex;
      .list{
        width: 50%;
        height: 350px;
        display: flex;
        flex-wrap: wrap;
        margin-top: 80px;
        .item{
          width: 50%;
          .item-content {
            width: 164px;
            display: flex;
            flex-direction: column;
            align-items: center;
            p{
              color: #fff;
              font-size: 16px;
              font-weight: 400;
              font-style: normal;
            }
            img{
              width: 80px;
              height: 80px;
            }
          }
        }
      }
    }
    .animation{
      position: relative;
      width: 100%;
      height: 660px;
      margin-top: -120px;
      img{
        width: 1200px;
        position: absolute;
        left: 100%;
        top: 50%;
        transform: translate(-30%,-50%);
        z-index: -1;
      }
      video{
        width: 1200px;
        position: absolute;
        left: 100%;
        top: 50%;
        z-index: -1;
        transform: translate(-30%,-50%);
      }
    }

  }
}


@media screen and (max-width: 1500px) {
  .tucana_build{
    .center{
      .list_animation{
        .animation{
          margin-left: -60px;
          img{
            width: 1000px;
          }
          video{
            width: 1000px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .tucana_build{
    .center{
      .list_animation{
        overflow: hidden;
        .animation{
          margin-top: -80px;

        }
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .tucana_build{
    overflow: hidden;
    .center{
      .btn_container {
        display: flex;
        justify-content: center;
      }
      .part_title {
        padding: 60px 16px 0;
      }
      .desc{
        margin-top: 20px;
        line-height: 24px;
        font-size: 16px;
        padding: 0 16px;
      }
      .list_animation{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .list{
          width: 100%;
          margin-top: 40px;
          margin-bottom: -40px;
          .item{
            width: 50%;
            margin-bottom: 40px;
            .item-content {
              width: 100%;
            }
            p{
              font-size: 16px;
            }
          }
        }
        .animation{
          margin-left: -240px;
          margin-top: -100px;
          img{
            width: 920px;
          }
          video{
            width: 920px;
          }
        }
      }
    }
  }
}

@primary-color: #1DA57A;