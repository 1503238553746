@keyframes fadeInMove {
  0% {
      opacity: 0;
      transform: translate(0, 0);
  }
  50% {
      opacity: 1;
      transform: translate(40px, 40px);
  }
  100% {
      opacity: 0;
      transform: translate(40px, 40px);
  }
}

@keyframes fadeInMove1 {
  0% {
      opacity: 0;
      transform: translate(0, 0);
  }
  50% {
      opacity: 1;
      transform: translate(-40px, -40px);
  }
  100% {
      opacity: 0;
      transform: translate(-40px, -40px);
  }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes moveUpDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}
@keyframes floatAnimation {
  0% {
      transform: translateY(0);
  }
  50% {
      transform: translateY(-20px);
  }
  100% {
      transform: translateY(0);
  }
}

@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(50px);
}
50% {
    opacity: 1;
    transform: translateY(0);
}
100% {
    opacity: 0;
    transform: translateY(-50px);
}
}

@keyframes line1 {
  0% {
      height: 0;
      top: 0px;
  }
  100% {
      height: 171px;
  }
}
@keyframes line2 {
  0% {
      width: 0;
  }
  100% {
      width: 120px;
  }
}
@keyframes line3 {
  0% {
      height: 0;
  }
  100% {
      height: 25px;
  }
}

@keyframes line4 {
  0% {
      height: 0;
      top: 0;
  }
  100% {
      height: 40px;
      top: -40px;
  }
}
@keyframes line5 {
  0% {
      width: 0;
  }
  100% {
      width: 102px;
  }
}
@keyframes line6 {
  0% {
      height: 0;
  }
  100% {
      height: 220px;
  }
}
@keyframes line7 {
  0% {
      height: 0;
      top: 0;
  }
  100% {
      height: 40px;
      top: -40px;
  }
}
@keyframes line8 {
  0% {
      width: 0;
  }
  100% {
      width: 102px;
  }
}
@keyframes line9 {
  0% {
      height: 0;
  }
  100% {
      height: 220px;
  }
}
@keyframes line10 {
  0% {
      height: 0;
      top: 0;
  }
  100% {
      height: 40px;
      top: -40px;
  }
}
@keyframes line11 {
  0% {
      width: 0;
  }
  100% {
      width: 102px;
  }
}
@keyframes line12 {
  0% {
      height: 0;
  }
  100% {
      height: 220px;
  }
}
@keyframes line13 {
  0% {
      height: 0;
      top: 0;
  }
  100% {
      height: 40px;
      top: -40px;
  }
}
@keyframes line14 {
  0% {
      width: 0;
  }
  100% {
      width: 102px;
  }
}
@keyframes line15 {
  0% {
      height: 0;
  }
  100% {
      height: 220px;
  }
}