.tucana_potv{
  position: relative;
  overflow: hidden;
  .video_box{
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    video{
      width: 100%;
      min-width: 2000px;
      height: auto;
    }
  }
  .center{
    position: relative;
    .tucana_potv_list{
      display: flex;
      justify-content: space-between;
      margin-top: 40px;
      .item{
        width: 32%;
        height: auto;
        display: flex;
        flex-direction: column;
        padding: 0 16px;
        background: #040D10;
        border-radius: 20px;
        border: 1px solid #253444;
        backdrop-filter: blur(20px);
        p{
          font-weight: 600;
          font-size: 20px;
          color: #FFFFFF;
          text-shadow: 3px 2px 17px rgba(0,0,0,0.5);
          margin-top: 40px;
          margin-bottom: 20px;
          font-weight: 400;
          line-height: 20px;
          font-style: normal;
        }
        span{
          color: rgba(#fff,0.7);
          font-size: 14px;
          line-height: 28px;
          text-shadow: 3px 2px 17px rgba(0,0,0,0.5);
        } 
        img {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (min-width: 2000px) {
  .video_box{
    width: 100%;
    video{
      width: 100% !important;
    }
  }
}
@media screen and (max-width: 750px) {
  .tucana_potv{
    width: 100%;
    overflow: hidden;
    .video_box{
      display: none !important;
    }
    .center{
      .tucana_potv_tit {
        padding: 60px 16px 0;
      }
      .tucana_potv_list{
        flex-wrap: wrap;
        margin: 20px 16px 0;
        .item{
          width: 100%;
          margin-top: 20px;
          padding-top: 50px;
          >div{
            >div{
              display: flex;
              flex-direction: column;
              align-items: center;
              p{
                width: 100%;
                text-align: left;
              }
            }
          }
          &:nth-child(1){
            margin-top: 0;
          }
          p{
            height: auto;
            margin-top: 0;
            line-height: 28px;
          }
          span{
            height: auto;
          }
          img{
            width: 310px;
            height: auto;
            margin: auto;
          }
        }
      }
    }
  }
}

@primary-color: #1DA57A;