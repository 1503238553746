
.banner{
  position: relative;
  height: 900px;
  overflow:hidden ;
  padding-top: 40px;
  .modal_style{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    min-width: 100vw;
    min-height: 100vh;
    z-index: 999;
    background: rgba(0, 0, 0, 0.95);
    .modal_content{
      width: 420px;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: "100%";
      height: "100%";
      background: #030712;
      z-Index: 1000;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      align-items: center;
      .icon_box{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        .icon{
          width: 20px;
          height: 20px;
          cursor: pointer;
        }
      }
      .img_error{
        width: 148px;
        height: 112px;
        margin-bottom: 16px;
      }
      .text{
        width: 100%;
        text-align: center;
        color: rgba(#fff, 0.8);
        font-size: 14px;
      }
    }
  }

  .content{
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    .header{
      width: 100%;
      margin-top: 48px;
      .center{
        width: calc(100% - 80px);
        display: flex;
        justify-content: space-between;
      }
      .pc_logo {
        width: 220px;
        height: 42px;
        margin-bottom: 100px;
      }
      .socical{
        width: 40%;
        .item{
          position: relative;
          cursor: pointer;
          &:hover{
            .hover_list{
              display: block;
            }
          }
          .tit{
            margin-top: 12px;
          }
          .hover_list{
            position: absolute;
            top: 36px;
            left: -20px;
            min-width: 104px;
            background: #171717;
            border-radius: 8px;
            backdrop-filter: blur(10px);
            padding: 0 20px 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            display: none;
            a{
              white-space: nowrap;
            }
          }
        }
      }
    }
    .banner_text{
      margin-top: 40px;
      display: flex;
      .banner_bg {
        width: 0;
        height: 340px;
        z-index: -1;
        position: relative;
        img{
          width: 1300px;
          height: auto;
          position: absolute;
          right: 7%;
          top: -80px;
        }
        video{
          width: 1300px;
          height: auto;
          position: absolute;
          right: 7%;
          top: -80px;
        }
      }
      .banner_halo {
        position: absolute;
        height: 360px;
        left: -180px;
        bottom: -280px;
      }
      .center{
        height: 380px;
        border-radius: 20px;
        padding: 76px 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        p{
          font-size: 88px;
          text-align: start;
          font-weight: bold;
          background: linear-gradient(135deg, #7BE2FE 0%, #FFFFFF 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-weight: bold;
          line-height: 88px;
          &:nth-child(2){
            background: linear-gradient(270deg, #7BE2FE 0%, #FFFFFF 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-top: 20px;
          }
        }
        .desc{
          text-align: left;
          font-size: 28px;
          color: #FFFFFF;
          line-height: 42px;
          text-shadow: 3px 2px 17px rgba(0,0,0,0.5);
          margin-top: 60px;
        }
      }
    }
  }
}

@media screen and (min-width: 1920px) {
  .banner_bg {
    right: 10% !important;
  }
}
@media screen and (max-width: 1440px) {
  .banner{
    height: 800px;
    .content{
      .header{
        margin-bottom: 0;
      }
      .banner_text{
        .banner_bg {
          // height: 580px;
          img{
            width: 1100px;
          }
          video{
            width: 1100px;
          }
        }
        .banner_halo {
          height: 280px;
          left: -140px;
          bottom: -200px;
        }
        .center{
          // padding: 90px 0;
          p{
            font-size: 60px;
            line-height: 60px;
          }
          .desc{
            margin-top: 40px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .banner{
    height: 750px;
    padding-top: 100px;
    .content{
      .banner_text{
        padding: 0 40px;
        margin-top: 0;
        .banner_bg {
          // height: 480px;
          margin-right: -80px;
          img{
            width: 1000px;
            top: -30px;
          }
          video{
            width: 1000px;
            top: -30px;
          }
        }
        .banner_halo {
          height: 200px;
          left: -100px;
          bottom: -80px;
        }
        .center{
          width: 100%;
          p{
            font-size: 60px;
          }
          .desc{
            font-size: 28px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .banner{
    height: 650px;
    padding-top: 140px;
    .content{
      .banner_text{
        .banner_bg {
          margin-right: -60px;
          img{
            width: 800px;
          }
          video{
            width: 800px;
          }
        }
        .banner_halo {
          height: 140px;
          left: -70px;
          bottom: -40px;
        }
        .center{
          justify-content: unset;
          padding: 30px 0;
          p{
            line-height: 70px;
          }
          .desc{
            font-size: 22px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .banner{
    padding-top: 0;
    height: 840px;
    .content{
      .header{
        .center{
          margin-bottom: 60px;
          align-items: center;
          width: 100%;
          padding: 0 20px;
          .pc_logo{
            margin-bottom: 0;
          }
          .socical{
            display: none;
          }
          .h5_socical{
            display: block;
            position: fixed;
            top: 0;
            left: 0;
            background: rgba(0,0,0,0.6);
            backdrop-filter: blur(10px);
            width: 70%;
            height: 100vh;
            z-index: 999;
            overflow: scroll;
            .pc_logo{
              width: 145px;
              height: 28px;
              padding: 50px 20px 40px;
            }
            .item{
              padding-left: 40px;
              margin-bottom: 40px;
              .hover_list{
                display: block;
                position: unset;
                background: none;
                backdrop-filter: none;
                transform: none;
                padding: 0;
              }
            }
          }
        }
        .menu_box{
          width: 32px;
          height: 32px;
          background: rgba(0,0,0,0.34);
          border-radius: 8px;
          backdrop-filter: blur(8px);
          display: flex;
          align-items: center;
          justify-content: center;
          .icon_menu{
            display: block;
            width: 20px;
            height: 20px;
          }
        }
      }
      .banner_text{
        padding: 0 8px;
        margin-top: 0;
        display: flex;
        flex-direction: column;
        position: unset;
        align-items: center;

        .banner_bg {
          margin-right: 0;
          img{
            top: -10px;
            left: 50%;
            right: unset;
            transform: translateX(-66%);
          }
          video{
            top: -10px;
            left: 50%;
            right: unset;
            transform: translateX(-66%);
          }
        }
        .banner_halo {
          display: none;
        }
        .center{
          justify-content: unset;
          padding: 0px 0;
          align-items: center;
          height: unset;
          margin-bottom: 10px;
          .title {
            font-weight: bold;
            text-align: center;
            font-size: 38px;
            line-height: 52px;
            font-style: normal;
            background: linear-gradient(135deg, #7BE2FE 0%, #FFFFFF 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            &:nth-child(2){
              background: linear-gradient(270deg, #7BE2FE 0%, #FFFFFF 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              margin-top: 20px;
            }
          }
          .desc{
            font-weight: 400;
            font-size: 16px;
            color: #FFFFFF;
            line-height: 28px;
            text-shadow: 3px 2px 17px rgba(0,0,0,0.5);
            text-align: center;
            font-style: normal;
            margin-top: 20px;
          }
          .h5_btn{
            display: block;
            margin-top: 50px;
            span{
              margin-top: 0;
            }
          }
        }
      }
    }
  }
}

@primary-color: #1DA57A;