.tucana_provides{
  background: url('../image/bg@2x.png') 50% no-repeat;
  background-size: cover;
  overflow: hidden;
  .center{
    .part_title{
      margin-bottom: 40px;
      font-weight: 400;
      font-size: 42px;
      color: #FFFFFF;
      line-height: 60px;
      text-shadow: 0px 2px 16px rgba(0,0,0,0.5);
      font-style: normal;
    }
    .h5_title{
      display: none;
    }
    .rowList{
      width: 100%;
      display: flex;
      justify-content: space-between;

      .item{
        width: 32%;
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: linear-gradient( 180deg, #000 0%, #1B1C1D 100%),
        linear-gradient(90deg, #000 0%, #1B1C1D 50%, #000 100%),
        linear-gradient(-90deg, #000 0%, #1B1C1D 50%, #000 100%);
        border-radius: 20px;
        border: 1px solid #253444;
        backdrop-filter: blur(20px);

        img{
          width: 200px;
          height: 200px;
        }
        .tit{
          font-size: 20px;
          color: #FFFFFF;
          line-height: 48px;
          padding: 0 20px 8px;
          margin: 16px 0;
          border-bottom: 1px solid #253444;

        }
        .desc{
          font-size: 14px;
          color: rgba(#fff,0.7);
          line-height: 24px;
          padding: 0 20px;
          text-shadow: 3px 2px 17px rgba(0,0,0,0.5);

        }
      }
    }
    .rowBottomList{
      margin-top: calc(4% / 2);
      .item{
        width: 48%;
        img{
          width: auto;
          height: 160px;
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .tucana_provides{
    .center{
      .content{
        .rowList{
          .item{
            width: 32%;
            padding-bottom: 20px;
          }
        }
        .rowBottomList{
          .item{
            width: 49%;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 960px) {
  .tucana_provides{
    .center{
      .content{
        .rowList{
          .item{
            .desc{
              font-size: 16px;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .tucana_provides{
    .center{
      .part_title{
        margin-bottom: 20px;
      }
      .h5_title{
        display: block;
        font-weight: 400;
        font-size: 28px;
        color: #FFFFFF;
        line-height: 42px;
        text-shadow: 0px 2px 16px rgba(0,0,0,0.5);
        text-align: left;
        font-style: normal;
        padding: 0 16px;
      }
      .pc_title{
        display: none;
      }
      .content{
        .rowList{
          flex-wrap: wrap;
          margin-top: 0px;
          .item{
            width: 100%;
            margin: 20px 16px 0;
            padding-bottom: 18px;
            .desc{
              font-size: 14px;
            }
          }
          .bg_amms {
            margin-top: 0;
          }
        }
        .rowBottomList{
          .item{
            overflow: hidden;
          }
          .item img {
            width: auto;
            height: 140px;
            margin: 40px 0 20px;
          }
        }
      }
    }
  }
}



@primary-color: #1DA57A;