.tucana_chain{
  margin-bottom: -60px;
  .center{
    .h5_tit{
      display: none;
    }
    .part_title {
      padding-top: 0;
    }
    .label_icon{
      width: 100%;
      display: flex;
      justify-content: space-around;
      .item{
        display:flex;
        flex-direction: column;
        align-items: center;
        width: 25%;
        margin-top: 40px;
        img{
          width: 120px;
          height: auto;
        }
        .label{
          font-size: 16px;
          color: #FFFFFF;
        }
      }
    }
  }
  .animation_box{
    z-index: -1;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 600px;
    img{
      width: 1600px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }
    video{
      width: 1600px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }
  }
  .h5_animation_box{
    display: none;
  }
}

@media screen and (max-width: 1500px) {
  .tucana_chain{
    .center{
      .label_icon{
        .item{
          img{
            width: 100px;
          }
        }
      }
    }
    .animation_box{
      height: 450px;
      video{
        width: 100%;
      }
      img{
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .tucana_chain{
    .center{
      .label_icon{
        flex-wrap: wrap;
        .item{
          width: 50%;
        }
        .label{
          white-space: nowrap;
        }
      }
    }
  }
}


@media screen and (max-width: 1000px) {
  .tucana_chain{
    .animation_box{
      img{
        width: 1200px;
      }
      video{
        width: 1200px;
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .tucana_chain{
    .center{
      .pc_tit{
        display: none;
      }
      .h5_tit{
        display: block;
      }
      .part_title {
        padding: 0 16px;
      }
      .label_icon{
        .item{
          width: 50%;
          margin-bottom: 40px;
          .label{
            min-width: 150px;
            text-align: center;
            font-size: 16px;
            white-space: wrap;
          }
        }
      }
    }
    .animation_box{
      margin-top: -40px;
      height: 490px;
      img{
        top: 35%;
        margin-left: 10px;
      }
      video{
        top: 35%;
        margin-left: 10px;
      }
    }
    .h5_animation_box{
      display: block;
    }
    .pc_animation_box{
      display: none;
    }
  }
}

@primary-color: #1DA57A;